import {WebStorageStateStore, InMemoryWebStorage, UserManagerSettings} from "oidc-client-ts";

const store = typeof window !== "undefined" ? window.sessionStorage : new InMemoryWebStorage()
export const loginConfig: {
    app2: UserManagerSettings;
    app1: UserManagerSettings
} = {
    app1: {
        client_id: "301ea15f-d604-4b36-8b49-ad517d0cdec0",
        authority: "https://b2clogin.jgrpdaz.de/jgrnoncitest.onmicrosoft.com/B2C_1_LOGIN",
        redirect_uri: process.env.REDIRECT_URI + "/app1",
        metadata: {
            issuer: "https://jgrnoncitest.b2clogin.com/06828c5e-3449-4ec0-bf52-d6dfc6297184/v2.0/",
            authorization_endpoint: "https://b2clogin.jgrpdaz.de/jgrnoncitest.onmicrosoft.com/b2c_1_login/oauth2/authorize",
            token_endpoint: "https://jgrnoncitest.b2clogin.com/jgrnoncitest.onmicrosoft.com/b2c_1_login/oauth2/token",
            end_session_endpoint: "https://jgrnoncitest.b2clogin.com/jgrnoncitest.onmicrosoft.com/b2c_1_login/oauth2/v2.0/logout",
            jwks_uri: "https://jgrnoncitest.b2clogin.com/jgrnoncitest.onmicrosoft.com/b2c_1_login/discovery/v2.0/keys"
        },
        userStore: new WebStorageStateStore({store: store, prefix: "app1_"}),
    },
    app2: {
        client_id: "be369bb2-b1cb-4e9d-a928-e20113c3fa89",
        authority: "https://b2clogin.jgrpdaz.de/jgrnoncitest.onmicrosoft.com/B2C_1_LOGIN",
        redirect_uri: process.env.REDIRECT_URI + "/app2",
        metadata: {
            issuer: "https://jgrnoncitest.b2clogin.com/06828c5e-3449-4ec0-bf52-d6dfc6297184/v2.0/",
            authorization_endpoint: "https://b2clogin.jgrpdaz.de/jgrnoncitest.onmicrosoft.com/b2c_1_login/oauth2/authorize",
            token_endpoint: "https://jgrnoncitest.b2clogin.com/jgrnoncitest.onmicrosoft.com/b2c_1_login/oauth2/token",
            end_session_endpoint: "https://jgrnoncitest.b2clogin.com/jgrnoncitest.onmicrosoft.com/b2c_1_login/oauth2/v2.0/logout",
            jwks_uri: "https://jgrnoncitest.b2clogin.com/jgrnoncitest.onmicrosoft.com/b2c_1_login/discovery/v2.0/keys"
        },
        userStore: new WebStorageStateStore({store: store, prefix: "app2_"})
    }
};
