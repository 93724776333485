import {User, UserManager, UserManagerSettings} from "oidc-client-ts";

export class ApplicationsManagerRedirect {

    private readonly appConfigs: Record<string, UserManagerSettings>;
    private readonly userManagers: Record<string, UserManager>;

    public constructor(appConfigs: Record<string, UserManagerSettings>) {
        this.appConfigs = appConfigs;
        this.userManagers = {};
        for (const addAppId in this.appConfigs) {
            this.userManagers[addAppId] = new UserManager(this.appConfigs[addAppId]);
        }
    }

    private getUserManager(appId: string): UserManager {
        if (this.userManagers[appId] == undefined) {
            throw `Unknown appId: ${appId}`
        }
        return this.userManagers[appId];
    }

    public async getUser(appId: string, autoLoginWhenAnyLogin = true) {
        const user = await this.getUserManager(appId).getUser();
        if (user || !autoLoginWhenAnyLogin) {
            return user;
        }
        let seemsLoggedIn = false;
        for (const tryAppId in this.userManagers) {
            if (await this.userManagers[tryAppId].getUser()) {
                seemsLoggedIn = true;
                break;
            }
        }
        return seemsLoggedIn ? this.loginSilent(appId) : null;
    }

    public async login(appId: string) {
        return this.getUserManager(appId)
            .signinRedirect({})
    }

    private async handleSilentRedirectFailed(appId: string) {
        console.log("Silent login not possible. Redirecting now");
        return this.login(appId)
    }

    private async loginSilent(appId: string) {
        return this.getUserManager(appId)
            .signinSilent({})
            .catch(err => {
                console.log(`Error on silent login: ${err}`)
                this.handleSilentRedirectFailed(appId)
            })
    }

    public handleLoginButtonState(appId: String, button: HTMLButtonElement, user: User|void) {
        if (!user) {
            button.innerHTML = `Login for ${appId}`
            button.onclick = () => {
                this.login(appId).then(() => {
                });
            }
        } else {
            button.innerHTML = `Login for ${appId} is available`
            console.log(appId, user)
            button.onclick = () => {
                window.alert("You are already logged in")
            }
        }
    }
    public handleTokenButtonState(appId: String, button: HTMLButtonElement, user: User|void) {
        if (!user) {
            button.disabled = true
            button.innerHTML = "Not logged in"
        } else {
            button.innerHTML = `Get token for ${appId}`
            button.onclick = () => {
                console.log(user.access_token);
            }
        }
    }

    public async handleResponse(appId: String, isSilent: boolean): Promise<void> {
        if (isSilent) {
            return await this.getUserManager(appId)
                .signinSilentCallback()
        }
        await this.getUserManager(appId)
            .signinRedirectCallback()
    }
}
