import {ApplicationsManagerRedirect} from "./ApplicationsManagerRedirect";
import {loginConfig} from "./login.config";
import parseUrl from 'parse-url';
import {Log} from "oidc-client-ts";

Log.setLogger(console);

window.onload = () => {
    const urlParsed = parseUrl(window.location.href);
    // an error occured on idp, so it decided to give us an error-message
    if (urlParsed.query.error) {
        console.log(urlParsed.query.error_description);
        return;
    }
    const appsManager = new ApplicationsManagerRedirect(loginConfig);
    // code-parameter is only given on idp-redirect when returning to reply-url
    if (urlParsed.pathname.indexOf("/.authcallback") === 0) {
        const isSubdocument = window.parent && window.parent != window
        console.log("handling response: " + urlParsed.pathname.substring("/.authcallback".length + 1) + " isSilent: " + (isSubdocument ? 1 : 0))
        appsManager.handleResponse(urlParsed.pathname.substring("/.authcallback".length + 1), isSubdocument)
            // after handling the response, redirect back to root (to prevent unnecessary code-parsing on refresh)
            .catch(err => {
                window.alert(err);
            })
            .finally(() => window.location.href = "/");
        return;
    }
    // handle all applications' login-buttons
    document
        .querySelectorAll("div.appreg")
        .forEach((appDiv, key) => {
            const appId = appDiv.id;
            appsManager
                .getUser(appId)
                .then(user => {
                    appsManager.handleLoginButtonState(
                        appId,
                        <HTMLButtonElement>appDiv.querySelector("button.login"),
                        user)
                    appsManager.handleTokenButtonState(
                        appId,
                        <HTMLButtonElement>appDiv.querySelector("button.token"),
                        user)
                })


        })
}
